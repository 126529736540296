
<template>
  <div>

    <div class="block w-full text-right mr-2 mb-8">
        <!-- <router-link to="/dashboard" class=" text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به داشبورد</router-link> -->
    </div>
    <vx-card id="ecommerce-checkout-demo" class="bg-h-primary-light">

    <div class="my-5 bg-h-primary p-5 rounded-lg flex w-full" >

      <b-form @submit.stop.prevent="addInstance()" class="w-full">

        <div class="md:flex justify-between gap-4 mb-10" style="direction: ltr;">
          <div class="w-full md:w-1/2">
          </div>
          <div class="w-full md:w-1/2">
            <span class="block"> عنوان</span>
            <input type="text" class="form-control bg-h-primary-light border-grey border-dashed border-opacity-25 h-12 placeholder-white placeholder-opacity-25" placeholder="Server Name" v-model="form.serverName" v-on:keypress="isLetter($event)" required>
            <span class="text-sm text-muted block"> فقط لاتین و بیشتر از 3 حرف باشد</span>
          </div>

        </div>

        <div class="w-full bg-h-primary-light p-2 md:p-5 rounded border-white border-dashed border-opacity-25 border">

          <div class="md:flex text-sm md:text-base justify-between mb-8 ">  موقعیت مکانی را انتخاب کنید:

            <div class="md:right-0 ">
                <b-button @click="getCountries()" class="w-full mt-2">تازه سازی</b-button>
              </div>
          </div>
          <div class="">
            <!-- Disabled Location -->
            <div class="lg:flex block w-full text-center justify-start gap-3">

              <!-- Enabled Location -->
              <b-form-radio
                class="mb-2 md:mb-0 block w-full"
                button
                button-variant="outline-light"
                v-model="form.selectedCountry"
                v-for= "(country, cid) in countries" :key="cid"
                @change="getDatacenters(country.id)" :value="country.id">
                <div class="md:flex w-full items-center gap-2 px-6 py-2 rounded  ">

                  <img  width="50" class="mb-2 md:mb-0" :src="`data:image/png;base64,${country.country_flag_file.attachment_content}`" />
                  {{country.country_name}}

               </div>
              </b-form-radio>

          </div>
          <div v-if="(form.selectedCountry !== '' && locations !== '')" class="text-center">

            <span class="text-white block my-4">دیتاسنتر را انتخاب کنید:</span>
            <b-form-radio
            class="ml-2 my-2 block m-auto"
            button
            button-variant="outline-light"
            v-model="form.selectedlocation"
            :key="nid" v-for="(location, nid) in locations"
            @change="getImageList(form.selectedlocation), getPlanList(form.selectedlocation)"
            :value="location.id">
            <span class="p-3">
              {{location.title}}
            </span>
          </b-form-radio>
        </div>

          </div>
        </div>

        <div  v-if="(form.selectedlocation > 0)" class="mt-3 mb-3 bg-h-primary-light p-5 rounded border-white border-dashed border-opacity-25 border">
        <b-tabs v-if="(form.selectedlocation > 0)" content-class="mt-3" class="mb-3 " >

          <b-tab class="p-5" title="سیستم عامل" v-if="(form.selectedlocation > 0 & this.imageList.length > 0)" active>
            <div class="lg:flex md:justify-center md:gap-3 w-full block text-center flex-wrap items-center bg-gray ">

              <div :key="os" v-for="(os) in imageList">
                  <b-form-radio button button-variant="outline-light"
                    v-model="form.selectedImage" :key="os"
                  :value="os.id"
                    class="border-opacity-25 w-full mb-2 lg:mb-0">
                    <div class="md:flex md:items-center md:gap-2 px-10 py-2  rounded">

                      <img  width="50" class="m-auto md:m-0 mb-2 md:mb-0" :src="`data:image/png;base64,${os.image_logo.attachment_content}`" />
                      {{os.title}}
                    </div>
                  </b-form-radio>
              </div>
            </div>

          </b-tab>
        </b-tabs>
    </div>

        <div  v-if="(form.selectedImage > 0)" class="mt-3 mb-3 bg-h-primary-light p-5 rounded border-white border-dashed border-opacity-25 border">
        <b-tabs v-if="(form.selectedImage > 0)" content-class="mt-3" class="mb-3 " >

          <b-tab class="p-5" title="انتخاب پلن" v-if="(form.selectedlocation > 0 & this.plans.length > 0)" active>
            <div class="lg:flex md:justify-center md:gap-3 w-full block text-center flex-wrap items-center bg-gray ">

              <div :key="plan" v-for="(plan) in plans">
                  <b-form-radio button button-variant="outline-light"
                    v-model="form.selectedPlan" :key="plan"
                                @change="setPlanResource(form.selectedPlan)"
                  :value="plan.id"
                    class="border-opacity-25 w-full mb-2 lg:mb-0">
                    <div class="md:flex md:items-center md:gap-2 px-10 py-2  rounded">
                      {{plan.plan_title}}
                    </div>
                  </b-form-radio>
              </div>
            </div>


            <div v-if="(form.selectedPlan !== '')" class="mt-3 lg:flex md:justify-center md:gap-3 w-full block text-center flex-wrap items-center bg-gray ">
              <div class="bg-grey-light text-h-primary p-2 rounded-lg rounded-t-sm text-center">
                <span class=" text-sm"> Ram: {{ ram }} | </span>
                <span class=" text-sm"> CPU: {{ cpu }} | </span>
                <span class=" text-sm"> Hard: {{ hard }}</span>
              </div>
            </div>

          </b-tab>
        </b-tabs>
    </div>

        <b-row v-if="(form.selectedPlan !== '')" class="block md:flex mt-16 items-center p-5 rounded border-white border-dashed border-opacity-25 border">

        <b-col lg="6" md="6" class="text-center md:text-right flex gap-1">

          <div class="bg-grey-light text-h-primary p-2 text-center">
            <span class=" text-sm"> هزینه ساعتی (روشن): {{ online_price }} تومان</span>
          </div>

          <div class="bg-grey-light text-h-primary p-2 text-center">
            <span class=" text-sm"> هزینه ساعتی (خاموش): {{ shutdown_price }} تومان</span>
          </div>

        </b-col>

        <b-col lg="6" md="6" class="text-center md:text-right flex justify-end gap-1">
          <b-button type="submit" variant="primary" class="block text-base md:text-lg px-5 md:px-10 py-3">ساخت دسکتاپ</b-button>
        </b-col>
    </b-row>

      </b-form>
    </div>

  </vx-card>
</div>
</template>

<script>
const names = require('file-loader!./names.txt')

export default {
  data () {
    return {
      form: {
        serverName: '',
        names: [],
        selectedlocation: '',
        selectedDatacenter:'',
        selectedCountry:'',
        selectedImage:'',
        selectedPlan:''
      },
      countries: {},
      plans: {},
      country:'',
      imageList: {},
      SelectedPlan: {},
      ram:'',
      cpu:'',
      hard:'',
      online_price:0,
      shutdown_price:0,
      datacenters: [],
      locations: []
    }
  },
  computed: {
    // monthPrice () {
    //   return parseInt(this.prices) * 24 * 30
    // }
  },
  watch: {
  },
  methods: {
    isLetter (e) {
      const char = String.fromCharCode(e.keyCode) // Get the character
      if (/^[A-Z-a-z0-9]+$/.test(char)) return true // Match with regex
      else this.sweetAlert(['خطا در نام سرور', 'لطفا بدون فاصله و فقط لاتین تایپ کنید', 'باشه', 'false']) // If not match, don't add to input text
    },
    sweetAlert (txt) {
      if (txt[3] !== 'false') {
        this.$swal({
          title: txt[0],
          text: txt[1],
          confirmButtonText : txt[2]
        }).then(() => {
          this.$router.push(txt[3])
        })
      } else {
        this.$swal({
          title: txt[0],
          text: txt[1],
          confirmButtonText : txt[2]
        })
      }
    },
    onSubmit (event) {
      event.preventDefault()
    },
    getCountries () {
      this.$vs.loading()
      this.form.selectedImage = ''
      this.form.selectedPlan = ''
      this.form.selectedlocation = ''
      this.form.selectedDatacenter = ''
      this.form.selectedCountry = ''
      this.$http
        .get('/cloud/desktop/location')
        .then((res) => {
          this.$vs.loading.close()
          const data = res.data.params
          const parsedobj = JSON.stringify(data.data)
          this.countries = JSON.parse(parsedobj)
        })
        .catch((err) => {
          this.$vs.loading.close()
          this.$vs.notify({
            color: 'danger',
            title: err.response.data.message
          })
        })
    },
    getDatacenters (id) {
      this.form.selectedImage = ''
      this.form.selectedPlan = ''
      this.form.selectedlocation = ''
      this.form.selectedDatacenter = ''
      this.locations = []
      this.plans = {}
      this.imageList = {}
      this.datacenters = this.countries.find((car) => car.id === parseInt(id)).datacenters
      this.datacenters.forEach(datacenter => {
        datacenter.locations.forEach(location => {
          this.locations.push(location)
        })
      })

    },
    getImageList (id) {
      if (id !== '') {

        this.form.selectedlocation = id
        this.form.selectedImage = ''
        this.form.selectedPlan = ''

        this.$vs.loading()
        this.$http
          .get(`/cloud/desktop/images?location=${id}`)
          .then((res) => {
            this.$vs.loading.close()
            const data = res.data.params
            // console.log('data as data', data)
            const parsedobj = JSON.stringify(data.data)
            const p = JSON.parse(parsedobj)
            this.imageList = p
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              color: 'danger',
              title: err.response.data.message
            })
          })
      }
    },
    getPlanList (id) {
      if (id !== '') {

        this.$vs.loading()
        this.$http
          .get(`/cloud/desktop/plans?location=${id}`)
          .then((res) => {
            this.$vs.loading.close()
            const data = res.data.params
            // console.log('data as data', data)
            const parsedobj = JSON.stringify(data.data)
            const p = JSON.parse(parsedobj)
            this.plans = p
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              color: 'danger',
              title: err.response.data.message
            })
          })
      }
    },
    setPlanResource (id) {
      if (id !== '') {
        this.SelectedPlan = this.plans.find((car) => car.id === parseInt(id))
        this.cpu = this.SelectedPlan.plan_cpu
        this.ram = this.SelectedPlan.plan_ram
        this.hard = this.SelectedPlan.plan_storage
        this.online_price = this.SelectedPlan.plan_online_price
        this.shutdown_price = this.SelectedPlan.plan_shutdown_price
      }
    },

    async addInstance () {

      const insData = {
        vm_title: this.form.serverName,
        template_id: this.form.selectedImage,
        plan_id: this.form.selectedPlan,
        location_id: this.form.selectedlocation
      }

      this.$vs.loading()
      try {
        // eslint-disable-next-line no-unused-vars
        await this.$http.post('/cloud/desktop', insData)
        this.sweetAlert(['دسکتاپ مجازی ساخته شد', 'اطلاعات ورود به دسکتاپ مجازی به ایمیل شما ارسال خواهد شد', 'لیست دسکتاپ های مجازی', '/vdi/list'])

      } catch (err) {
        this.$vs.notify({
          color: 'danger',
          title: ' خطا',
          text: err.response.data.message
        })
      }
      this.$vs.loading.close()


    }
  },

  components: {

  },
  mounted () {


  },
  created () {
    this.getCountries()
    fetch(names)
      .then(response => response.text())
      .then(data => {
        this.names = data.split(',')
        this.form.serverName = this.names[Math.floor(Math.random() * this.names.length)]
      })

  }
}
</script>
<style>
.vs-slider--circle {border-radius: 50% }
[dir=rtl] .text-circle-slider.vs-slider--circle-text{    font-family: sans-serif;
    font-size: 16px;
    direction: ltr !important;
}
.theme-dark .con-vs-slider .vs-slider {
    background: #ffffffa1;
    height: 6px;
    border-radius: 50px;
}
.nav-item a {
  color: #696b6d !important;
}
.swal2-popup {
  width: 35em !important;
}
</style>
